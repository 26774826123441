import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Logo from "../../components/logo/Logo";
import NavList from "./NavList";
import { FaBars, FaCheck } from "react-icons/fa";
import MobileNavList from "./MobileNavBar";
import { StyledContainer } from "../layout/Layouts";

const Navbar = (props) => {
  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    return setOpen((prev) => !prev);
  };

  const [float, setFloat] = useState(false);

  const handleScroll = (e) => {
    // let element = e.target;
    if (window.scrollY >= 100) {
      setFloat(true);
    } else {
      setFloat(false);
    }
  };

  useEffect(() => {
    // window.scroll(0, 0);
    if (window.scrollY >= 100 && !float) {
      setFloat(true);
    }
    window.addEventListener("scroll", handleScroll);
  }, [float]);

  return (
    <StyledWrapper float={float}>
      <StyledContainer>
        <StyledNavbar {...props}>
          <Logo
            size={"small"}
            color={"white"}
            title={"CAD CONSULTING LIMITED"}
          />
          <NavList type={"desktop"} float={float} />
          <span className={"menu-icon"} onClick={toggleMenu}>
            {!open ? <FaBars /> : <FaCheck />}
          </span>
          <MobileNavList setOpen={setOpen} open={open} float={float} />
        </StyledNavbar>
      </StyledContainer>
    </StyledWrapper>
  );
};

export default Navbar;

const StyledWrapper = styled.div`
  background-color: ${(props) =>
    props.float === true ? "white" : "#ffffff00"};
  position: ${(props) => (props.float === true ? "fixed" : "unset")};
  left: 0;
  top: 0;
  width: 100%;
  height: 64px;
  /* z-index: 10000; */
  z-index: 100;
  box-shadow: ${(props) =>
    props.float === true ? "0 5px 10px #00000045" : "red"};
  transition: position 0.3s ease;
`;
const StyledNavbar = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: inherit;

  .menu-icon {
    color: ${(props) => (props.float === true ? "green" : "blue")} !important;
    /* color: red; */
    font-size: 24px;
    display: none;
    cursor: pointer;
    transition: all 0.3s ease;

    @media screen and (max-width: 768px) {
      & {
        display: unset;
      }
    }
  }
`;
