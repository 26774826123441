// import React from "react";
import styled from "@emotion/styled";
// import headerBG from "../../assets/images/header-bg.png";
// import footerBg from "../../assets/images/img3.jpg";
// import map from "../../assets/images/map.png";
// import user from "../../assets/images/user-2.jpg";
// import outroImg from "../../assets/images/img1.jpg";
// import teamBg from "../../assets/images/team-bg.jpg";
// import web1 from "../../assets/images/cad-web.png";
// import web2 from "../../assets/images/web-2.png";
// import web3 from "../../assets/images/nirsal.png";
const BGs = {
  // team: teamBg,
  team: "https://ik.imagekit.io/6mmfyfoux/site/team-bg.jpg?tr=w-750",
};
const map = "https://ik.imagekit.io/6mmfyfoux/site/map.png?tr=w-750";

export const StyledAboutUs = styled.div`
  /* background-color: #000026; */
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#000026")};
  .section-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 30px;
    text-align: left;
  }

  .description {
    color: ${(props) => (props.textColor ? props.textColor : "#fff")};
    margin-top: 30px;
  }
  button {
    width: 100%;
    max-width: 200px;
    display: block;
    margin: 20px 0;
  }
  .bottom-button {
    display: none;
    margin-top: 40px;
  }

  .text-content {
    width: 50%;
    /* max-width: 400px; */
  }

  .image-content {
    width: 40%;
    max-width: 600px;
    /* padding: 2rem; */
    text-align: center;

    img {
      height: ${(props) => (props.imgHeight ? props.imgHeight : "auto")};
      width: ${(props) => (props.imgWidth ? props.imgWidth : "90%")};
      box-shadow: 10px 10px 0 0 red;
    }
  }
  @media screen and (max-width: 768px) {
    .text-content {
      width: 100%;
      max-width: 100%;
    }
    .image-content {
      width: 100%;
      img {
        //height: 300px;
        width: 100%;
      }
    }
    button {
      display: none;
    }
    .image-content .bottom-button {
      display: initial;
    }
  }
`;

export const FlexCard = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  min-height: 200px;
  /* max-width: 400px; */
  min-width: 250px;
  height: 300px;
  background: ${(props) => (props.color ? props.color : "#fff")};

  display: flex;
  flex-wrap: wrap;
  flex-direction: ${(props) =>
    props.direction === "right" ? "row-reverse" : "row"};
  justify-content: center;
  align-items: center;
  /* flex: 1; */

  > div {
    width: 43%;
    flex: 1;
  }

  @media screen and (max-width: 625px) {
    height: 200px;
  }
  @media screen and (max-width: 529px) {
    width: 100% !important;
  }

  .text-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .image-section {
    overflow: hidden;
    height: 100%;
    background-color: #fff;

    /* clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 47%,
      96% 50%,
      100% 53%,
      100% 100%,
      0% 100%
    ); */
    clip-path: ${(props) =>
      props.direction === "right"
        ? `polygon(
      0% 0%,
      100% 0%,
      100% 100%,
      0% 100%,
      0% 53%,
      4% 50%,
      0% 47%      
    )`
        : `polygon(
      0% 0%,
      100% 0%,
      100% 47%,
      96% 50%,
      100% 53%,
      100% 100%,
      0% 100%
    )`};

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }

  h3 {
    color: ${(props) => (props.color ? "#fff" : "red")};
    font-weight: bold;
  }
  p {
    color: ${(props) => (props.color ? "#fff" : "#000026")};
  }
`;

export const StyledPage = styled.div`
  width: 100%;
  /* height: 100%; */
  /* background-color: green; */
  overflow: hidden;
  /* overflow-x: hidden; */
`;

export const StyledContainer = styled.div`
  width: 100%;
  padding: 2rem;
  max-width: 1300px;
  min-height: 100%;
  margin: 0 auto;
  height: 100%;
  position: relative;

  @media screen and (max-width: 768px) {
    & {
      padding: 1rem;
    }
  }
  @media screen and (max-width: 1000px) {
    & {
      padding: 1.5rem;
    }
  }
`;

export const StyledSection = styled.section`
  width: 100%;
  padding: 40px 0;
  min-height: 100%;

  @media screen and (max-width: 768px) {
    & {
      padding: 20px 0;
    }
  }
  @media screen and (max-width: 1000px) {
    & {
      padding: 30px 0;
    }
  }
`;

export const StyledOutroSection = styled.div`
  background-color: navy;
  background-image: url("https://ik.imagekit.io/6mmfyfoux/site/img1.jpg?tr=w-750");
  background-size: cover;
  background-blend-mode: multiply;
  background-position: center center;
  clip-path: polygon(
    0% 0%,
    100% 0%,
    100% 95%,
    53% 95%,
    50% 100%,
    47% 95%,
    0% 95%
  );
  .content-section {
    display: flex;
    height: 100%;
    min-height: 300px;
    color: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      margin-bottom: 3rem;
      font-size: 3rem;
      text-align: center;
    }
  }
`;

export const StyledFlexContainer = styled.div`
  display: flex;
  width: 100%;
  height: 350px;
  min-height: fit-content;
  flex-direction: ${(props) => (props.index % 2 !== 0 ? "row" : "row-reverse")};
  gap: 40px;
  flex: wrap;
  /* margin: 40px auto; */
  align-items: center;
  justify-content: center;

  .gallery-image {
    width: 70%;
    height: 100%;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    user-select: none;
    pointer-events: none;
    img {
      width: 75%;
    }
  }

  .service-image {
    width: 45%;
    height: 100%;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    user-select: none;
    pointer-events: none;
    img {
      width: 60%;
    }
  }
  .service-context {
    .service-category {
      color: red;
      text-transform: uppercase;
      letter-spacing: 2px;
    }
    .service-name {
      color: navy;
      font-size: 3rem;
    }
    .service-sub-headline {
      color: #33333355;
    }

    .line {
      margin: 10px 0 20px 0;
      width: 40px;
      background-color: red;
      height: 5px;
      border: none;
      border-radius: 20px;
    }

    .service-desc {
      width: 100%;
      max-width: 550px;
      color: #333;
      margin-bottom: 20px;
      line-height: 2;
      font-size: 1.6rem;
    }

    button {
      background-color: navy;
      display: block;
      width: 100%;
      max-width: 150px;
      border-radius: 30px;
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    height: ${(props) =>
      props.direction === "rtl" && props.direction === "ltr"
        ? "0px"
        : "fit-content"};

    justify-content: center;
    text-align: center;

    .line {
      /* margin: 20px auto !important; */
    }
    .service-context,
    .service-image {
      width: 90%;
    }
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    height: ${(props) =>
      props.direction === "rtl" && props.direction === "ltr"
        ? "0px"
        : "fit-content"};
    justify-content: center;
    text-align: center;
    .service-image {
      width: 90%;
      height: 100%;
      display: flex;
      margin-left: 100px;
      align-items: center;

      img {
        width: 60%;
      }
    }

    .service-context {
      width: 90%;
    }
  }
`;

export const StyledHeader = styled.div`
  width: 100%;
  height: fit-content;
  height: ${(props) => (props.height ? props.height : "800px")};
  min-height: ${(props) => (props.height ? props.height : "100vh")};
  /* min-height: fit-content; */
  /* min-height: 100vh; */
  max-height: 1000px;
  background-color: #332c72;
  background-color: #000026;
  /* background-image: ${(props) =>
    props.bg ? `url(${BGs[props.bg]})` : `url()`}; */
  background-image: ${(props) =>
    props.bg
      ? `url(${BGs[props.bg]})`
      : `url('https://ik.imagekit.io/6mmfyfoux/site/header-bg.png?tr=w-800')`};
  /* overflow: hidden; */
  background-size: cover;
  background-blend-mode: ${(props) => (props.bg ? "multiply" : "normal")};
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: -10px;
  position: relative;
  padding-bottom: 128px;

  .header-section {
    display: flex;
    flex-direction: column;
    height: ${(props) => (props.center ? "100%" : "unset")};
    /* background-color: purple; */
    h1 {
      font-size: 5rem;
      max-width: 80%;
      margin-bottom: 40px;
      margin-top: 100px;
      line-height: 1.2;
      font-family: "Poppins", san-sans-serif;
    }
    p {
      font-size: 2rem;
      max-width: 60%;
    }
    .header-image {
      /* margin-bottom: -60px; */
      margin: 100px auto -80px auto;
      position: relative;
      height: unset;
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      gap: 30px;
      /* background-color: red; */
    }
    @keyframes slide {
      0% {
        background-position-x: 0, 0, 0, 0, 0, 0, 0;
      }
      24.8% {
        background-position-x: 0, 0, 0, 0, 0, 0, 0;
      }
      26.8% {
        background-position-x: -1000px, 0, 0, 0, 0, 0, 0;
      }
      36.8% {
        background-position-x: -1000px, 0, 0, 0, 0, 0, 0;
      }
      38.8% {
        background-position-x: -1000px, -1000px, 0, 0, 0, 0, 0;
      }
      48.8% {
        background-position-x: -1000px, -1000px, 0, 0, 0, 0, 0;
      }
      50.8% {
        background-position-x: -1000px, -1000px, -1000px, 0, 0, 0, 0;
      }
      60.8% {
        background-position-x: -1000px, -1000px, -1000px, 0, 0, 0, 0;
      }
      62.8% {
        background-position-x: -1000px, -1000px, -1000px, -1000px, 0, 0, 0;
      }
      72.8% {
        background-position-x: -1000px, -1000px, -1000px, -1000px, 0, 0, 0;
      }
      74.8% {
        background-position-x: -1000px, -1000px, -1000px, -1000px, -1000px, 0, 0;
      }
      84.8% {
        background-position-x: -1000px, -1000px, -1000px, -1000px, -1000px, 0, 0;
      }
      86.8% {
        background-position-x: -1000px, -1000px, -1000px, -1000px, -1000px,
          -1000px, 0;
      }
      96.8% {
        background-position-x: -1000px, -1000px, -1000px, -1000px, -1000px,
          -1000px, 0;
      }
      100% {
        background-position-x: -1000px, -1000px, -1000px, -1000px, -1000px,
          -1000px, 0;
      }
    }

    .header-laptop {
      margin-left: -100px;
      margin-right: -100px;
      z-index: 5;
      width: 70%;
      position: relative;
      img {
        width: 100%;
      }
      .laptop-slider {
        height: 78%;
        width: 74%;
        background-color: blue;
        position: absolute;
        top: 7%;
        left: 13%;
        background: url("https://ik.imagekit.io/6mmfyfoux/site/team-bg.jpg?tr=w-750")
            center center,
          url("https://ik.imagekit.io/6mmfyfoux/site/img1.jpg?tr=w-750") center
            center,
          url("https://ik.imagekit.io/6mmfyfoux/site/nirsal.png?tr=w-750")
            center center,
          url("https://ik.imagekit.io/6mmfyfoux/site/cad-web.png?tr=w-750")
            center center,
          url("https://ik.imagekit.io/6mmfyfoux/site/img1.jpg?tr=w-750") center
            center,
          url("https://ik.imagekit.io/6mmfyfoux/site/nirsal.png?tr=w-750")
            center center,
          url(${map}) center center;
        background-size: cover, cover, cover, cover, cover, cover, cover;
        background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat,
          no-repeat, no-repeat;
        background-position-x: 0, 0, 0, 0, 0, 0, 0;
        animation: slide 60s ease infinite;
      }
    }

    .header-tab {
      width: 25%;
      margin-left: 0px;
      position: relative;
      margin-bottom: 2px;

      img {
        width: 100%;
        /* transform: rotateZ(90deg); */
      }
      .tab-slider {
        height: 80%;
        width: 90%;
        background-color: blueviolet;
        position: absolute;
        top: 9%;
        left: 5%;
        /* transform: rotateZ(90deg); */
        background: url(${map}) center center,
          url("https://ik.imagekit.io/6mmfyfoux/site/team-bg.jpg?tr=w-750")
            center center,
          url("https://ik.imagekit.io/6mmfyfoux/site/img1.jpg?tr=w-750") center
            center,
          url("https://ik.imagekit.io/6mmfyfoux/site/nirsal.png?tr=w-750")
            center center,
          url("https://ik.imagekit.io/6mmfyfoux/site/cad-web.png?tr=w-750")
            center center,
          url(${map}) center center,
          url("https://ik.imagekit.io/6mmfyfoux/site/nirsal.png?tr=w-750")
            center center;
        /* background: url() center center, url() center center,
          url() center center, url() center center,
          url() center center, url() center center,
          url() center center; */
        background-size: cover, cover, cover, cover, cover, cover, cover;
        background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat,
          no-repeat, no-repeat;
        background-position-x: 0, 0, 0, 0, 0, 0, 0;
        animation: slide 60s ease infinite;
      }
    }
    .header-mobile {
      width: 18%;
      margin-bottom: 70px;
      position: relative;

      img {
        width: 100%;
      }

      .mobile-slider {
        height: 80%;
        width: 90%;
        background-color: rebeccapurple;
        position: absolute;
        top: 9%;
        left: 5%;

        background: url("https://ik.imagekit.io/6mmfyfoux/site/img1.jpg?tr=w-750")
            center center,
          url("https://ik.imagekit.io/6mmfyfoux/site/nirsal.png?tr=w-750")
            center center,
          url("https://ik.imagekit.io/6mmfyfoux/site/cad-web.png?tr=w-750")
            center center,
          url("https://ik.imagekit.io/6mmfyfoux/site/team-bg.jpg?tr=w-750")
            center center,
          url("https://ik.imagekit.io/6mmfyfoux/site/nirsal.png?tr=w-750")
            center center,
          url(${map}) center center,
          url("https://ik.imagekit.io/6mmfyfoux/site/team-bg.jpg?tr=w-750")
            center center;
        /* background: url() center center, url() center center,
          url() center center, url() center center,
          url() center center, url() center center,
          url() center center; */
        background-size: cover, cover, cover, cover, cover, cover, cover;
        background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat,
          no-repeat, no-repeat;
        background-position-x: 0, 0, 0, 0, 0, 0, 0;
        animation: slide 60s ease infinite;
      }
    }
  }

  .header-content {
    text-align: center;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    flex: 1;
  }

  .header-cta {
    margin: 100px auto 20px auto;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    pointer-events: none;
    gap: 20px;
    align-items: center;
    justify-content: center;
  }

  .others {
    height: 500px;
    background-color: red;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    height: fit-content;
    & {
      /* .desktop-navbar {
        display: none;
      } */
      .header-title {
        font-size: 2rem;
        margin-top: 3rem;
        /* padding: 0.5rem; */
        max-width: 100%;
      }

      & p {
        margin: 2rem auto;
        max-width: 100%;
      }

      .header-cta > button {
        width: 100%;
        max-width: 200px;
      }
      .header-laptop {
        width: 100%;
        margin: 0 auto !important;
      }

      .header-tab,
      .header-mobile {
        display: none;
      }
      .header-section .header-image {
        height: unset;
      }

      .header-section p {
        max-width: 90%;
      }

      .header-section h1 {
        font-size: 5vw;
      }
    }
  }

  @media screen and (max-width: 425px) {
    .header-section h1 {
      font-size: 8vw;
      /* margin-top: 1rem; */
    }

    .header-section p {
      max-width: 100%;
      margin: 1rem auto;
    }
    .header-cta {
      margin: 30px auto 10px auto;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      pointer-events: none;
      gap: 20px;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const StyledServices = styled.section`
  background-color: #fff;
  width: 100%;

  .more-btn {
    margin: 3.5rem auto 0 auto;
    display: block;
  }

  .text-area {
    max-width: 900px;
    margin: auto;
    p {
      margin-top: 10px;
    }
  }

  .services-wrapper {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 20px;
    /* display: flex;
    justify-content: ${(props) => (props.wrap ? "center" : "space-between")};
    align-items: center;
    gap: 30px;
    flex-wrap: ${(props) => (props.wrap ? "wrap" : "nowrap")};
    border-radius: 10px;
    padding: ${(props) => (props.wrap === "card" ? "5rem" : " 2rem 3.5rem")};
    width: 100%;
    max-width: ${(props) => (props.wrap === "card" ? "1000px" : "inherit")};
    overflow-x: auto;
    margin: ${(props) =>
      props.wrap === "card" ? "-300px auto 100px auto" : "unset"};
    border-radius: 10px;
    background-color: #fff;
    box-shadow: ${(props) =>
      props.wrap === "card" ? " 0 0 10px #00002623" : "none"}; */

    @media screen and (max-width: 900px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media screen and (max-width: 400px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    @media screen and (max-width: 500px) {
      display: ${(props) => (props.wrap === "card" ? "none" : "")};
    }

    /* .service-card {
      width: ${(props) => (props.wrap === "card" ? "30%" : "22%")};
      min-width: ${(props) => (props.wrap ? "unset" : "200px")};
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      gap: 10px;
      max-width: 180px;

      @media screen and (max-width: 900px) {
        width: ${(props) => (props.wrap === "card" ? "30%" : "40%")};
      }

      @media screen and (max-width: 500px) {
        width: ${(props) => (props.wrap === "card" ? "30%" : "90%")};
      }

      h3 {
        color: maroon;
        font-weight: bold;
      }
      p {
        max-width: 300px;
      }

      .service-icon {
        color: navy;
        background-color: blue;
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .icon {
          font-size: 4rem;
          color: #fff;
        }
      }
    } */
  }
`;
